import * as React from "react";
import Layout from "../components/layout";

const TermsPage = () => {
  const termsRef = React.useRef();

  React.useEffect(() => {
    if (termsRef.current == null) {
      return;
    }
    console.log("effect");

    const js = document.createElement("script");
    js.id = "termly-terms";
    js.src = "https://app.termly.io/embed-policy.min.js";
    //@ts-ignore
    termsRef.current.parentNode.insertBefore(js, termsRef.current);
  });

  return (
    <Layout hasLongInner>
      <div
        //@ts-ignore
        name="termly-embed"
        data-id="72816007-5f64-408e-97f0-12108c71454e"
        data-type="iframe"
        ref={termsRef}
      ></div>

      <div
        //@ts-ignore
        name="termly-embed"
        data-id="cb8aa35b-f042-4e9e-afe6-5a146facc3e1"
        data-type="iframe"
      ></div>
    </Layout>
  );
};

export default TermsPage;
